<template>
  <!-- 轮播图 -->
  <div id="banner">
    <!--动态将图片轮播图的容器高度设置成与图片一致-->
    <el-carousel class="elCarousel" :height="bannerHeight + 'px'">
      <!--遍历图片地址,动态生成轮播图-->
      <el-carousel-item v-for="(item, index) in deliverParentPic" :key="index">
        <img :src="item"  />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
      // 图片父容器高度
      bannerHeight: 351,
      // 浏览器宽度
      screenWidth: 0
    }
  },
  props: {
    deliverParentPic: Array
  },
  methods: {
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (400 / 1920) * this.screenWidth
    },
    mounted () {
      // 首次加载时,需要调用一次
      this.screenWidth = window.innerWidth
      this.setSize()
      // 窗口大小发生改变时,调用一次
      window.onresize = () => {
        this.screenWidth = window.innerWidth
        this.setSize()
      }
    }
  }
}
</script>

<style>
.el-carousel {
  width: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel__button {
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.el-carousel__indicators--horizontal {
  left: 85%;
}

.el-carousel__indicator.is-active button {
  background-color: #2e88e4;
}

img {
  /*设置图片宽度和浏览器宽度一致*/
  width: 100%;
  height: inherit;
}
</style>
