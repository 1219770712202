<template>
  <div>
    <optionscard :deliverParentNum="3"></optionscard>
    <breadcrumb></breadcrumb>
    <div class="width1200_1">
      <!-- 图片和展品信息 -->
      <div class="middle-div">
        <div class="carouselDiv">
          <!-- 轮播图 -->
          <carousel :deliverParentPic="articulars.slider_image"></carousel>
        </div>
        <!-- 展品信息 -->
        <div class="exhibits-div">
          <!-- 展品信息标题 -->
          <div class="exhibits-head">
            {{ articulars.title }}
          </div>
          <!-- 展品信息内容 -->
          <div class="exhibits-content">
            <div>展品简介：</div>
            <div v-html="articulars.content"></div>
          </div>
        </div>
      </div>
      <!-- 相关展品-->
      <div v-if="exhibitsList">
        <div class="correlation-exhibits">
          <div class="bluebulk"></div>
          <div class="bluebulk-word">相关展品</div>
        </div>
        <template v-if="exhibitsList.length">
          <!-- 相关展品3个图片 -->
          <div class="correlation-exhibits-div">
            <div
              class="correlation-exhibits-picture"
              v-for="item in exhibitsList"
              :key="item.id"
              @click="getCorrelation(item.id)"
            >
              <!-- 展品图片 -->
              <div class="correlation-exhibits-picture">
                <img :src="item.image" width="100%" height="100%" />
              </div>
              <!-- 展品名称 -->
              <div class="pitures-word">{{ item.title }}</div>
            </div>
          </div>
        </template>
        <!-- 如果搜索不到就显示这个 -->
        <el-empty description="无相关展品" v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
import carousel from '../../components/Carousel.vue'
export default {
  data () {
    return {
      // 存放文章详情数据
      articulars: {},
      // 存放展品列表数据
      exhibitsList: [],
      // 存放展品列表参数
      exhibitsarticle: {
        pid: 3,
        paginate: 3
      }
    }
  },
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb,
    // 轮播图
    carousel
  },
  created () {
    console.log('id', this.$route.query.id)
    const id = this.$route.query.id
    this.getArticle(id)
    this.getExhibits(id)
  },
  methods: {
    // 文章详情
    async getArticle (id) {
      const { res } = await this.$api.venueexhibits.getArticle({ id })
      console.log('res', res)
      this.articulars = res
      console.log(this.articulars)
    },
    // 获取展品列表
    async getExhibits (id) {
      const { res } = await this.$api.venueexhibits.getExhibits(
        this.exhibitsarticle
      )
      console.log('exhibits res', res)
      res.data.forEach((i, index, array) => {
        if (i.id === Number(id)) {
          res.data.splice(index, 1)
        }
      })
      this.exhibitsList = res.data
    },
    // 跳转相关展品的文章
    async getCorrelation (id) {
      console.log('id', id)
      this.$router.push({
        path: '/third/cgzpfirst/cgzpsecond/cgzpthird',
        query: { id }
      })
    }
  },
  watch: {
    '$route.query.id' () {
      // 监听路由变化  // 组件第一次进入不会执行
      console.log('id 变化了', this.$route.query.id)
      this.getArticle(this.$route.query.id)
      this.getExhibits(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200_1 {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 151px;
}

.middle-div {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

.carouselDiv {
  width: 551px;
}

.exhibits-div {
  width: 630px;
  margin-top: 30px;
  overflow: hidden;
  height: 321px;
}

.exhibits-head {
  color: #333333;
  font-size: 24px;
  color: #333333;
  margin-left: 40px;
}

.exhibits-content {
  width: 518px;
  height: 159px;
  font-size: 14px;
  color: #606060;
  margin-left: 40px;
  margin-top: 24px;
}

.correlation-exhibits {
  display: flex;
  margin-top: 80px;
}

.correlation-exhibits-div {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.correlation-exhibits-picture {
  width: 1.9947rem;
  height: 250px;
  margin-left: 0.08854rem;
}

.correlation-exhibits-picture:hover {
  cursor: pointer;
}

.correlation-exhibits-picture:hover .pitures-word {
  color: #2e88e4;
}

.bluebulk {
  width: 5px;
  height: 18px;
  background-color: #2e88e4;
  display: inline-block;
  margin-top: 5px;
}

.bluebulk-word {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: #333333;
}

.pitures-word {
  text-align: center;
  font-size: 20px;
  color: #333333;
  margin: 0 auto;
  margin-left: 17px;
  width: 100%;
  border: 1px solid #eaeaea;
  padding: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-empty{
  padding: 0;
}
</style>
